import React, { useEffect } from 'react';

import './style.scss';
import { track } from '@/shared/analytics';

import { IPageContext } from '@/interfaces/pageContext';
import ProductCard from '@/components/ProductCard';
import { DefaultLayout } from 'src/layouts/DefaultLayout';

type Props = {
  pageContext: IPageContext;
};

const Products = ({ pageContext }: Props) => {
  const { categoriesWithProducts, appData } = pageContext;
  const { templateData, locale, products } = appData;
  const { home, menu } = templateData;

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: locale?.header?.products,
  };

  useEffect(() => {
    track('productListView', { template: 'virgoA' });
  }, []);

  return (
    <DefaultLayout {...defaultLayoutData}>
      <div
        role="region"
        className="default-screen-size products-listing-container"
      >
        <div className="products-listing-info">
          <h2>{home?.productList?.homeProductListTitle}</h2>
          <p>{home?.productList?.homeProductListSubTitle}</p>
        </div>

        {menu.categoriesMenu && categoriesWithProducts.length > 0 ? (
          categoriesWithProducts.map((category) => (
            <div className="products-listing-by-category">
              <h3>{category.categoryName || category.menu || category.name}</h3>
              <ul className="products-listing-content">
                {category.products?.map((product) => (
                  <ProductCard
                    key={product.id}
                    home={home}
                    locale={locale}
                    product={product}
                    hasAction={false}
                  />
                ))}
              </ul>
            </div>
          ))
        ) : (
          <ul className="products-listing-content">
            {products?.map((product) => (
              <ProductCard
                key={product.id}
                home={home}
                locale={locale}
                product={product}
                hasAction={false}
              />
            ))}
          </ul>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Products;
